@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");

:root {
  --colour-dark-highlight: hsl(195, 100%, 70%);
  --colour-highlight: hsl(195, 85%, 50%);
  --colour-bird: hsl(195, 75%, 55%);
  --colour-background: hsl(195, 80%, 95%);
  --colour-background-modal: hsl(0, 0%, 96%);
  --colour-background-search-results: whitesmoke;
  --colour-background-title: hsla(195, 100%, 50%, 0.25);
  --colour-background2: white;
  --colour-bird-border: rgb(99, 99, 99);
  --font-family-sans: "Red Hat Display", sans-serif;
  --font-family-fun: "Darumadrop One", sans-serif;
  --font-color: black;
  --bird-border-style: inset;
  --test: rgb(15, 15, 15);
  --scroll-animation-duration: 10s;
  --scroll-animation-translateX: -100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--colour-background);
  font-family: var(--font-family-sans);
  font-size: 12;
  color: var(--font-color);
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem auto auto;
  grid-template-areas:
    "header"
    "nav"
    "content";

  margin: 1vw auto;
  margin-bottom: 0rem;
  max-width: 100vw;
  min-width: 320px;
}

.btn {
  display: flex;
  align-items: center;
  border-radius: 10rem;
  border: none;

  text-transform: uppercase;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    margin: 0 0.2rem;
    height: 1.6rem;
    width: 1.6rem;
    fill: currentColor;
  }
}

.audio__container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  margin: auto;

  background-color: rgb(26, 26, 26);

  font-family: var(--font-family-fun);
  font-size: 1.1rem;
  color: white;
}

.audio__container__overlay {
  position: fixed;
  z-index: 4;
  left: 0;
  right: 0;
  bottom: 0;

  opacity: 80%;
  height: 0;

  background-color: black;
}

.audio__controls__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin: 10px 5px 0 5px;
  width: 100vw;
}

.audio__playback__container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.audio__selector__container {
  display: flex;
  flex-direction: row;

  height: 70px;
  padding: 5px;
  padding-top: 0;

  background-color: rgb(26, 26, 26);
}

.audio__selectors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.btn__selector {
  border: none;
  background-color: rgb(26, 26, 26);
}

.btn__selector-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.audio__selector__left {
  transform: rotate(-180deg);

  margin-right: 15px;
  height: 1.5rem;
  width: 1.5rem;
}

.audio__selector__right {
  margin-left: 15px;
  height: 1.5rem;
  width: 1.5rem;
}

.audio__selector__type {
  overflow: hidden;
  width: 33%;
}

.audio__selector__type-type {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.5rem;
}

.audio__type {
  width: 100%;
  text-align: center;
  transition: all 0.5s ease;
}

.audio__selector__type-controls {
  display: flex;
  justify-content: center;
}
.audio__selector__type-title {
  display: flex;
  justify-content: center;
}

.audio__selector__track {
  width: 33%;
}

.audio__selector__track-track {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}

.audio__selector__track-controls {
  display: flex;
  justify-content: center;
}
.audio__selector__track-title {
  display: flex;
  justify-content: center;
}

.btn__change__track {
  margin-bottom: 7px;
  border: 2px solid white;
  border-radius: 10px;
  padding: 0px 10px 7px 10px;

  animation: glowSelect 1s infinite;
  background-color: black;

  font-family: var(--font-family-fun);
  font-size: 1.2rem;
  color: white;

  &:hover {
    background-color: var(--colour-highlight);
    cursor: pointer;
  }
}

@keyframes glowSelect {
  0% {
    border-color: var(--colour-highlight);
    color: var(--colour-highlight);
  }
  50% {
    border-color: white;
    color: white;
  }
  100% {
    border-color: var(--colour-highlight);
    color: var(--colour-highlight);
  }
}

.header {
  grid-area: header;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  max-width: 100%;
}

.header__logo__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 60%;
  max-width: 244px;
  padding-left: 0;
}

.header__logo__container a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
  max-width: 244px;
}

.logo {
  height: 80%;

  &:hover {
    cursor: pointer;
  }
}

.header__logo {
  height: 100%;
  width: 4.5rem;
}

.search__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35%;
  padding-top: 1.2rem;
}

.search {
  position: relative;
  display: flex;
  border-radius: 10rem;

  align-items: center;

  margin-right: 0;
  max-width: 85vw;
  height: 2.5rem;
  padding-left: 2rem;

  background-color: var(--colour-background2);
}

.search__field {
  display: flex;

  border: none;
  min-width: 0;
  max-width: 50vw;

  background: none;

  font-family: inherit;
  color: inherit;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
}

.search-results__list {
  list-style: none;
}

.search-results {
  position: absolute;
  z-index: 10;
  top: 100%;

  width: 15rem;

  background-color: var(--colour-background-search-results);

  font-size: 1rem;
  color: var(--font-color);
}

.search-results__item {
  padding: 0.8rem;

  &:hover {
    cursor: pointer;
    background-color: var(--colour-bird);
  }
}

.search__btn {
  display: flex;

  border: 3px solid var(--font-color);
  height: 2.7rem;
  padding: 0.5rem 0.75rem;

  background-color: var(--colour-background);

  color: var(--font-color);
  /* font-weight: 400; */
  font-size: 1rem;
  /* text-wrap: nowrap; */
  white-space: nowrap;

  &:hover {
    transition: background-color 0.4s ease;
    background-color: var(--colour-highlight);
  }

  &:focus {
    background-color: inherit;
  }
}

.search__icon {
  height: 1.6rem;
  width: 1.6rem;
  fill: black !important;
  color: black;
}

.filter__btn {
  display: flex;

  border: 3px solid var(--font-color);
  height: 2.7rem;
  padding: 0 0.75rem;

  background-color: var(--colour-background);

  color: var(--font-color);
  /* font-weight: 400; */
  font-size: 1.5rem;
  text-wrap: nowrap;

  &:hover {
    transition: background-color 0.4s ease;
    background-color: var(--colour-highlight);
  }

  &:focus {
    background-color: inherit;
  }
}

.filter__icon {
  height: 1.6rem;
  width: 1.6rem;
}

.filter__list {
  position: absolute;
  right: 0;
  z-index: 10;
  /* bottom: -4px; */
  overflow: scroll;

  /* border: 3px solid black; */
  border-radius: 10px;
  height: 60vh;

  text-align: center;
  list-style: none;

  background-color: var(--colour-background2);
}

.filter__list a {
  text-decoration: none;
  color: var(--font-color);
}

.filter__title {
  padding: 0.5rem;
  color: rgb(255, 255, 255);
  background-color: rgba(128, 128, 128, 0.5);
}

.filter__title__birdsection {
  padding: 0.5rem;

  text-align: center;
  color: var(--font-color);
  background-color: var(--colour-background-title);
}

.filter__item {
  padding: 0.5rem;

  &:hover {
    cursor: pointer;
    background-color: var(--colour-bird);
  }
}

.nav {
  position: relative;
  grid-area: nav;
  display: flex;
  flex-direction: column;

  animation: glowNav 2s infinite;

  margin-top: 1.5rem;
  border: 3px;
  border-style: solid;
  border-color: var(--colour-background);
  height: auto;
  max-width: 100%;

  font-family: inherit;
  font-size: 1.25rem;

  background-color: var(--colour-background2);
}

@keyframes glowNav {
  0% {
    border-color: white;
  }

  50% {
    border-color: var(--colour-highlight);
  }
  100% {
    border-color: white;
  }
}

.nav__btn__container {
  display: flex;
  align-items: center;
  height: 50px;
  text-align: center;
}

.nav__title {
  text-align: center;
}

.nav__btn {
  margin-left: 6px;
  border: none;
  height: 50px;
  width: 50px;
  background: none;
}

.nav__btn__icon {
  height: 50px;
  width: 50px;
}

.nav__btn__down {
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
}

.nav__btn__up {
  transform: rotate(-180deg);
  transition: 0.2s ease-in-out;
}

.nav__menu {
  /* position: absolute;
  z-index: 4;
  left: 0;
  right: 0; */

  /* transform: translateY(53px); */

  display: flex;
  flex-direction: column;
  overflow: hidden;

  transition:
    height 0.5s ease,
    background-color 0.5s ease;

  height: 0px;
  /* width: 100%; */

  background-color: var(--colour-background2);
}

.nav__item {
  position: relative;
  display: flex;
  flex-direction: column;

  overflow: hidden;

  transition: 0.5s ease;

  min-height: 0;

  list-style-type: none;

  &:hover {
    cursor: pointer;
    background-color: var(--colour-bird);
  }
}

.nav__link {
  padding: 1rem;
  height: 100%;
  width: 100%;

  text-decoration: none;
  color: var(--font-color);
}

@keyframes menuSelect {
  0% {
    font-size: 1.25rem;
  }
  50% {
    font-size: 1.3rem;
    background-color: var(--colour-bird);
  }
  100% {
    font-size: 1.25rem;
    background-color: var(--colour-bird);
  }
}

.birds__container {
  grid-area: content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: auto;
  max-width: 100vw;
  padding: 0;
}

.birds__container__main__content {
  width: 100%;
}

.section__title {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  display: flex;

  font-family: var(--font-family-fun);
  font-size: 1.5rem;
}

.section__title-description {
  margin: 0 auto 0 auto;
  /* border-radius: 48% 10% 63% 33% / 10% 29% 16% 30%; */
  padding: 0 10px 5px 10px;

  font-family: var(--font-family-fun);
  font-size: 1.5rem;

  /* background-color: whitesmoke; */
}

.welcome {
  margin: 1rem;

  font-family: var(--font-family-fun);
  font-size: 1.5rem;
  text-align: center;
}

.welcome h1 {
  margin-bottom: 1rem;

  font-family: var(--font-family-fun);
  font-size: 1.5rem;
  text-align: center;
}

.contact {
  margin: 1rem;
  font-family: var(--font-family-fun);
  font-size: 1.5rem;
  text-align: center;
}

.disclaimer {
  margin: 1rem;
  font-family: var(--font-family-sans);
  font-size: 8px;
  text-align: center;
}

.copyright {
  text-align: center;
  font-family: var(--font-family-sans);
  font-size: 8px;
}

.worm {
  transition: all 0.25s;

  margin: 0 0.2rem;
  height: 3rem;
  width: 3rem;

  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.worm-menu {
  position: absolute;
  top: 5px;

  transform: translateX(10px);

  height: 3rem;
  width: 3rem;
}

.worm__instruction {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  text-align: center;
  font-family: var(--font-family-fun);
  font-size: 0.8rem;
}

@keyframes wormInstruction {
  0% {
    color: black;
  }

  50% {
    color: white;
  }

  100% {
    color: black;
  }
}

.birds__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  align-items: center;
  justify-content: center;
  list-style-type: none;
}

.bird {
  display: flex;
  overflow: hidden;

  transition: all 0.5s ease-out;

  margin-bottom: 1rem;
  border-style: solid;
  border-color: var(--colour-bird-border);
  border-width: 2px;
  border-top: 0;
  border-left: 0;
  border-color: var(--colour-bird-border);
  box-shadow: var(--colour-bird-border) 1px 2px 4px 0px;
  border-radius: 15px;
  max-height: 0rem;
  width: 100%;
  /* max-height: 15rem; */
  padding: 0.5rem;
  background-color: var(--colour-background2);
}

@media (hover: hover) and (pointer: fine) {
  .bird {
    &:hover {
      box-shadow: var(--colour-highlight) 1px 2px 4px 0px;
    }
  }
}

.bird__recordings__container {
  grid-area: recording;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: var(--font-family-fun);
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;

  cursor: pointer;

  svg {
    height: 1.1rem;
    width: 1.1rem;
    margin: 0 0.2rem;
  }
}

.scientific__name {
  font-style: italic;
}

.bird__recordings {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 1px;
}

.audio__text {
  text-align: center;
}

.audio__image {
  margin: 0 0.2rem;
  height: 1.1rem;
  width: 1.1rem;
}

.bird__image__container {
  grid-area: image;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  border-radius: 15px;
  width: 37%;
  padding: 0;

  cursor: pointer;
}

.bird__hidden {
  margin-bottom: none;
  border: none;
  height: 0;
  padding: 0;
}

.bird__image {
  /* position: absolute; */
  /* top: 0; */

  border-radius: 15px;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.info__icon {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 25px;
  width: 25px;
  padding-top: 0.2rem;
}

.info__icon__audio {
  position: absolute;
  left: 8.5px;
  top: 6px;
  height: 25px;
  width: 25px;
  padding-top: 0.2rem;
  cursor: pointer;
}

.heart__icon {
  position: absolute;
  right: 8.5px;
  top: 8px;
  height: 22px;
  width: 22px;

  cursor: pointer;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 15px;
  width: 8rem;
  height: 4rem;

  svg {
    margin: auto;
    /* max-height: 50px;
    max-width: 50px; */
  }
}

.spinner__section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17rem;
  width: 100%;
}

.spinner__overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17rem;
  width: 100%;
}

.spinner_svg {
  max-height: 50px;
  max-width: 50px;

  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bird__info__container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  max-width: 63%;
  padding-left: 0.75rem;
}

.bird__title_container {
  display: flex;
  justify-content: space-between;

  svg {
    height: 3rem;
    width: 3rem;
    margin: 0 0.2rem;
  }
}

.bird__info__title {
  margin: 0;
  grid-area: title;
}

.bird__details__container {
  padding-top: 2px;
}

.settings__container {
  margin-top: 1rem;
  width: 100%;

  transition: none;

  /* font-family: var(--font-family-fun); */
  /* font-size: 2rem;  */
  /* text-align: center; */
}

.setting {
  position: relative;
  display: flex;
  justify-content: space-between;

  margin-right: 1rem;
}

.setting__section {
  margin: 1.5rem 1rem 1rem 1rem;
}

.setting__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0.5rem 0 0.5rem 1rem;
  /* padding-right: 0.5rem; */
  /* text-align: center; */
}

.season__colours__container {
  display: flex;
  justify-content: space-evenly;

  margin-top: 1rem;
  width: 100%;
}

.season__colours__container button {
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 4px 0px;
  height: 60px;
  width: 60px;

  background: var(--colour-background2);

  font-family: var(--font-family-fun);
  color: var(--font-color);

  &:hover {
    border-color: var(--colour-highlight);
    box-shadow: var(--colour-highlight) 0px 1px 4px 0px;
    cursor: pointer;
    color: var(--colour-highlight);
  }
}

.btn__season {
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin: auto; */

  &:hover {
    svg {
      fill: var(--colour-highlight);
    }
  }
}

.svg__container__themes {
  height: 40px;
  width: 40px;

  fill: rgba(99, 99, 99, 0.2);
}

.theme__range {
  margin: 1rem 0 1rem 0;
}

.slider {
  -webkit-appearance: none;
  border-radius: 15px;
  outline: none;
  width: 100%;
  height: 3px;
  background: var(--colour-background2);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  border-radius: 25px;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: var(--colour-bird);
  cursor: pointer; /* Cursor on hover */
}

.bird__information__container {
  position: fixed;

  z-index: 5;
  top: 20px;
  left: 20px;
  right: 20px;

  /* transform: translate(50, -50%); */

  margin: auto;

  border-radius: 20px;
  max-width: 400px;

  background-color: var(--colour-background-modal);
}

.bird__information {
  display: flex;
  flex-direction: column;
  margin: 10px;

  color: black;
}

.bird__information__image__container {
  overflow: hidden;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 100%;
  max-height: 60vh;

  background-color: rgb(226, 226, 226);
}

.bird__information__image {
  border-radius: 20px;
  max-width: 100%;
}

.image__attribution {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;

  background-color: rgb(226, 226, 226);
}

.bird__information__attribution-owner {
  max-width: 70%;
  /* text-wrap: wrap; */
  /* white-space: nowrap; */
  overflow-wrap: break-word;
}

.bird__information__audio__attribution {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.audio__embed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.audio__embed p {
  padding-left: 0.6rem;
}

.close-info__btn {
  position: absolute;
  right: 0;

  transform: translate(12.5px, -12.5px);

  margin: none;
  border-radius: 25px;
  width: 31px;
  height: 31px;

  background-color: whitesmoke;

  &:hover {
    transform: translate(12.5px, -12.5px);
  }
}

.hidden {
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  margin: 0;
  height: 0;
  width: 0;
  padding: 0;
}

.overlay {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;

  transition: all 0.5s;

  width: 100%;
  height: 100%;
}

.overlay-blur {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;

  opacity: 60%;

  transition: all 0.5s;

  width: 100%;
  height: 100%;

  background-color: black;
}

.border__none {
  border: none;
}

/* SOUND WAVE ANIMATION */

@keyframes quiet {
  25% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.6);
  }
}
@keyframes loud {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(1.2);
  }
}

.audio__selector__soundwave {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.soundwave_container {
  display: flex;
  justify-content: space-between;
  height: 36px;
  --boxSize: 8px;
  --gutter: 4px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);
}

.wave {
  transform: scaleY(0.4);

  border-radius: 8px;
  height: 100%;
  width: var(--boxSize);
  background: var(--colour-highlight);

  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.wave1 {
  animation-name: quiet;
}

.wave2 {
  animation-name: normal;
}

.wave3 {
  animation-name: quiet;
}

.wave4 {
  animation-name: loud;
}

.wave5 {
  animation-name: quiet;
}

.stop_animation {
  animation: none;
}

/* Loading audio list animation */

.loading__colour__list {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;

  transform: translate(-50%, -50%);

  margin: 0;
  padding: 0;
}

.loading__colour__list li {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  list-style: none;
  background: #fff;
  animation: grow 1.6s ease-in-out infinite;
}
@keyframes grow {
  0%,
  40%,
  100% {
    transform: scale(0.2);
  }
  20% {
    transform: scale(1);
  }
}

.loading__colour__list li:nth-child(1) {
  animation-delay: -1.4s;
  background: #ffff00;
  box-shadow: 0 0 50px #ffff00;
}
.loading__colour__list li:nth-child(2) {
  animation-delay: -1.2s;
  background: #76ff03;
  box-shadow: 0 0 50px #76ff03;
}
.loading__colour__list li:nth-child(3) {
  animation-delay: -1s;
  background: #f06292;
  box-shadow: 0 0 50px #f06292;
}
.loading__colour__list li:nth-child(4) {
  animation-delay: -0.8s;
  background: #4fc3f7;
  box-shadow: 0 0 50px #4fc3f7;
}
.loading__colour__list li:nth-child(5) {
  animation-delay: -0.6s;
  background: #ba68c8;
  box-shadow: 0 0 50px #ba68c8;
}
.loading__colour__list li:nth-child(6) {
  animation-delay: -0.4s;
  background: #f57c00;
  box-shadow: 0 0 50px #f57c00;
}
.loading__colour__list li:nth-child(7) {
  animation-delay: -0.2s;
  background: #673ab7;
  box-shadow: 0 0 50px #673ab7;
}

.audio__track__details {
  display: flex;
  /* text-wrap: nowrap; */
  white-space: nowrap;

  max-width: 100vw;

  font-family: var(--font-family-sans);
  font-size: 12px;
  font-style: italic;

  /* background-color: rgb(49, 49, 49); */
}

.audio__track__details div {
  /* display: inline-flex; */
  overflow: hidden;
}
.audio__track__details__bird {
  white-space: nowrap;
}

.audio__track__details__scroller__text {
  z-index: 0;

  animation: trackScroller 10s infinite;
  animation-timing-function: linear;
}

.track__details__highlight {
  font-weight: 800;
  color: var(--colour-highlight);
}

@keyframes trackScroller {
  0% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(var(--scroll-animation-translateX));
  }

  50% {
    transform: translateX(var(--scroll-animation-translateX));
  }

  90% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

@media (orientation: landscape) and (min-width: 720px) {
  body {
    font-family: var(--font-family-sans);
    /* background: black; */
    background: #131313;
    /* background: black; */
  }

  .container {
    grid-template-columns: 0.8fr 2fr;
    grid-template-rows: 5rem calc(100vh - 6rem - 80px);
    grid-template-areas:
      "header header"
      "nav content";

    margin-top: 1rem;
    margin-bottom: 5rem;
    max-width: 800px;
  }

  .header {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    background: var(--colour-background);
  }

  .header__logo__container {
    height: 100%;
    max-width: 50%;
  }

  .logo {
    height: 100%;
  }

  .search__container {
    align-items: center;
    height: 100%;
    padding: 0;
  }

  .search {
    display: flex;
    border-radius: 10rem;
    display: flex;
    align-items: center;

    margin-right: 0;
    height: 2rem;
    max-width: 85vw;
    padding-left: 2rem;

    background-color: white;
  }
  /* 
  .filter__title__birdsection {
    background-color: rgb(20, 20, 20);
  } */

  .filter__list {
    right: -1rem;
  }

  .nav {
    margin-top: 0;
    border-color: var(--colour-background2);
  }

  .birds__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    overflow-x: hidden;

    height: calc(100vh - 6rem - 80px);

    background: var(--colour-background);
    /* background: var(--test); */
  }

  .audio__container {
    display: flex;
    flex-direction: row;

    height: 80px;
    max-width: 800px;

    font-family: var(--font-family-fun);
    font-size: 1.1rem;
    color: white;

    background-color: rgb(26, 26, 26);
    /* background-color: var(--colour-background); */
  }

  .audio__container__overlay {
    height: 80px;
  }

  .audio__playback__container {
    margin: 0;
    height: 100%;
    padding-left: 0.8rem;
  }

  .audio__controls__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin: 0;
    height: 100%;
    max-width: 800px;
  }

  .btn__selector {
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .btn__change__track {
    margin-bottom: 0;
  }

  .audio__selector__container {
    margin-right: 40px;
    margin-left: 1rem;
    height: 75%;
    width: 400px;
    padding: 0px 0.6rem 0px 0.6rem;
    background-color: rgb(25, 25, 25);
  }

  .audio__selector__type {
    width: 33%;
  }

  .loading__colour__container {
    position: relative;
    left: 95%;
  }

  .bird__information__container {
    top: 50%;
    left: 20px;
    right: 20px;

    transform: translate(0, -50%);

    border-radius: 20px;
    max-width: 700px;
    max-height: 90vh;
  }

  .bird__information__image__container {
    max-height: 80vh;
  }

  .bird__information {
    display: flex;
    flex-direction: row;
  }

  .bird__information__image__attribution {
    font-size: 0.5rem;
  }

  .audio__embed {
    margin-top: 0;
    align-items: none;
  }

  .info__icon__audio {
    position: absolute;
    top: auto;
    left: auto;
    right: 4.5px;
    bottom: 10px;
  }

  .audio__track__details {
    position: absolute;
    top: 0;

    width: min(100vw, 800px);

    transform: translateY(-1rem);

    background-color: rgba(0, 0, 0, 0.746);
  }
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle_slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .toggle_slider {
  background-color: var(--colour-bird);
}

input:focus + .toggle_slider {
  box-shadow: 0 0 1px var(--colour-bird);
}

input:checked + .toggle_slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.toggle_slider.round {
  border-radius: 34px;
}

.toggle_slider.round:before {
  border-radius: 50%;
}

.error__container {
  display: flex;
  justify-content: center;
}

.error__message {
  /* border-radius: 48% 10% 63% 33% / 10% 29% 16% 30%; */
  padding: 0.5rem;

  font-family: var(--font-family-fun);
  font-size: 1.5rem;

  /* background-color: whitesmoke;
  color: black; */
}
