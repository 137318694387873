@import "https://fonts.googleapis.com/css2?family=Honk&display=swap";
:root {
  --colour-dark-highlight: #66d9ff;
  --colour-highlight: #13b6ec;
  --colour-bird: #36b7e2;
  --colour-background: #e8f7fc;
  --colour-background-modal: #f5f5f5;
  --colour-background-search-results: whitesmoke;
  --colour-background-title: #00bfff40;
  --colour-background2: white;
  --colour-bird-border: #636363;
  --font-family-sans: "Red Hat Display", sans-serif;
  --font-family-fun: "Darumadrop One", sans-serif;
  --font-color: black;
  --bird-border-style: inset;
  --test: #0f0f0f;
  --scroll-animation-duration: 10s;
  --scroll-animation-translateX: -100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: var(--colour-background);
  font-family: var(--font-family-sans);
  color: var(--font-color);
  font-size: 12px;
}

.container {
  grid-template: "header" 10rem
                 "nav"
                 "content"
                 / 1fr;
  min-width: 320px;
  max-width: 100vw;
  margin: 1vw auto 0;
  display: grid;
}

.btn {
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 10rem;
  align-items: center;
  display: flex;

  &:focus {
    outline: none;
  }

  & svg {
    fill: currentColor;
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 .2rem;
  }
}

.audio__container {
  font-family: var(--font-family-fun);
  color: #fff;
  background-color: #1a1a1a;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
  font-size: 1.1rem;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.audio__container__overlay {
  z-index: 4;
  opacity: .8;
  background-color: #000;
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.audio__controls__container {
  flex-direction: column;
  justify-content: flex-end;
  width: 100vw;
  margin: 10px 5px 0;
  display: flex;
}

.audio__playback__container {
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.audio__selector__container {
  background-color: #1a1a1a;
  flex-direction: row;
  height: 70px;
  padding: 0 5px 5px;
  display: flex;
}

.audio__selectors {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.btn__selector {
  background-color: #1a1a1a;
  border: none;
}

.btn__selector-right {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.audio__selector__left {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 15px;
  transform: rotate(-180deg);
}

.audio__selector__right {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 15px;
}

.audio__selector__type {
  width: 33%;
  overflow: hidden;
}

.audio__selector__type-type {
  flex-direction: row;
  justify-content: center;
  font-size: 1.5rem;
  display: flex;
}

.audio__type {
  text-align: center;
  width: 100%;
  transition: all .5s;
}

.audio__selector__type-controls, .audio__selector__type-title {
  justify-content: center;
  display: flex;
}

.audio__selector__track {
  width: 33%;
}

.audio__selector__track-track {
  justify-content: center;
  font-size: 1.5rem;
  display: flex;
}

.audio__selector__track-controls, .audio__selector__track-title {
  justify-content: center;
  display: flex;
}

.btn__change__track {
  font-family: var(--font-family-fun);
  color: #fff;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-bottom: 7px;
  padding: 0 10px 7px;
  font-size: 1.2rem;
  animation: 1s infinite glowSelect;

  &:hover {
    background-color: var(--colour-highlight);
    cursor: pointer;
  }
}

@keyframes glowSelect {
  0% {
    border-color: var(--colour-highlight);
    color: var(--colour-highlight);
  }

  50% {
    color: #fff;
    border-color: #fff;
  }

  100% {
    border-color: var(--colour-highlight);
    color: var(--colour-highlight);
  }
}

.header {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  flex-flow: column wrap;
  grid-area: header;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  display: flex;
}

.header__logo__container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 244px;
  height: 60%;
  padding-left: 0;
  display: flex;
}

.header__logo__container a {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 244px;
  height: 100%;
  display: flex;
}

.logo {
  height: 80%;

  &:hover {
    cursor: pointer;
  }
}

.header__logo {
  width: 4.5rem;
  height: 100%;
}

.search__container {
  justify-content: center;
  align-items: center;
  height: 35%;
  padding-top: 1.2rem;
  display: flex;
  position: relative;
}

.search {
  background-color: var(--colour-background2);
  border-radius: 10rem;
  align-items: center;
  max-width: 85vw;
  height: 2.5rem;
  margin-right: 0;
  padding-left: 2rem;
  display: flex;
  position: relative;
}

.search__field {
  color: inherit;
  background: none;
  border: none;
  min-width: 0;
  max-width: 50vw;
  font-family: inherit;
  font-size: 1rem;
  display: flex;

  &:focus {
    outline: none;
  }
}

.search-results__list {
  list-style: none;
}

.search-results {
  z-index: 10;
  background-color: var(--colour-background-search-results);
  color: var(--font-color);
  width: 15rem;
  font-size: 1rem;
  position: absolute;
  top: 100%;
}

.search-results__item {
  padding: .8rem;

  &:hover {
    cursor: pointer;
    background-color: var(--colour-bird);
  }
}

.search__btn {
  border: 3px solid var(--font-color);
  background-color: var(--colour-background);
  color: var(--font-color);
  white-space: nowrap;
  height: 2.7rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  display: flex;

  &:hover {
    background-color: var(--colour-highlight);
    transition: background-color .4s;
  }

  &:focus {
    background-color: inherit;
  }
}

.search__icon {
  color: #000;
  width: 1.6rem;
  height: 1.6rem;
  fill: #000 !important;
}

.filter__btn {
  border: 3px solid var(--font-color);
  background-color: var(--colour-background);
  color: var(--font-color);
  text-wrap: nowrap;
  height: 2.7rem;
  padding: 0 .75rem;
  font-size: 1.5rem;
  display: flex;

  &:hover {
    background-color: var(--colour-highlight);
    transition: background-color .4s;
  }

  &:focus {
    background-color: inherit;
  }
}

.filter__icon {
  width: 1.6rem;
  height: 1.6rem;
}

.filter__list {
  z-index: 10;
  text-align: center;
  background-color: var(--colour-background2);
  border-radius: 10px;
  height: 60vh;
  list-style: none;
  position: absolute;
  right: 0;
  overflow: scroll;
}

.filter__list a {
  color: var(--font-color);
  text-decoration: none;
}

.filter__title {
  color: #fff;
  background-color: #80808080;
  padding: .5rem;
}

.filter__title__birdsection {
  text-align: center;
  color: var(--font-color);
  background-color: var(--colour-background-title);
  padding: .5rem;
}

.filter__item {
  padding: .5rem;

  &:hover {
    cursor: pointer;
    background-color: var(--colour-bird);
  }
}

.nav {
  border: 3px solid;
  border-color: var(--colour-background);
  background-color: var(--colour-background2);
  flex-direction: column;
  grid-area: nav;
  max-width: 100%;
  height: auto;
  margin-top: 1.5rem;
  font-family: inherit;
  font-size: 1.25rem;
  animation: 2s infinite glowNav;
  display: flex;
  position: relative;
}

@keyframes glowNav {
  0% {
    border-color: #fff;
  }

  50% {
    border-color: var(--colour-highlight);
  }

  100% {
    border-color: #fff;
  }
}

.nav__btn__container {
  text-align: center;
  align-items: center;
  height: 50px;
  display: flex;
}

.nav__title {
  text-align: center;
}

.nav__btn {
  background: none;
  border: none;
  width: 50px;
  height: 50px;
  margin-left: 6px;
}

.nav__btn__icon {
  width: 50px;
  height: 50px;
}

.nav__btn__down {
  transition: all .2s ease-in-out;
  transform: rotate(0);
}

.nav__btn__up {
  transition: all .2s ease-in-out;
  transform: rotate(-180deg);
}

.nav__menu {
  background-color: var(--colour-background2);
  flex-direction: column;
  height: 0;
  transition: height .5s, background-color .5s;
  display: flex;
  overflow: hidden;
}

.nav__item {
  flex-direction: column;
  min-height: 0;
  list-style-type: none;
  transition: all .5s;
  display: flex;
  position: relative;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: var(--colour-bird);
  }
}

.nav__link {
  color: var(--font-color);
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-decoration: none;
}

@keyframes menuSelect {
  0% {
    font-size: 1.25rem;
  }

  50% {
    background-color: var(--colour-bird);
    font-size: 1.3rem;
  }

  100% {
    background-color: var(--colour-bird);
    font-size: 1.25rem;
  }
}

.birds__container {
  flex-direction: column;
  grid-area: content;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  height: auto;
  padding: 0;
  display: flex;
}

.birds__container__main__content {
  width: 100%;
}

.section__title {
  font-family: var(--font-family-fun);
  font-size: 1.5rem;
  display: flex;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
}

.section__title-description {
  font-family: var(--font-family-fun);
  margin: 0 auto;
  padding: 0 10px 5px;
  font-size: 1.5rem;
}

.welcome {
  font-family: var(--font-family-fun);
  text-align: center;
  margin: 1rem;
  font-size: 1.5rem;
}

.welcome h1 {
  font-family: var(--font-family-fun);
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.contact {
  font-family: var(--font-family-fun);
  text-align: center;
  margin: 1rem;
  font-size: 1.5rem;
}

.disclaimer {
  font-family: var(--font-family-sans);
  text-align: center;
  margin: 1rem;
  font-size: 8px;
}

.copyright {
  text-align: center;
  font-family: var(--font-family-sans);
  font-size: 8px;
}

.worm {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  margin: 0 .2rem;
  transition: all .25s;

  &:hover {
    transform: scale(1.1);
  }
}

.worm-menu {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 5px;
  transform: translateX(10px);
}

.worm__instruction {
  text-align: center;
  font-family: var(--font-family-fun);
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: .8rem;
}

@keyframes wormInstruction {
  0% {
    color: #000;
  }

  50% {
    color: #fff;
  }

  100% {
    color: #000;
  }
}

.birds__list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  list-style-type: none;
  display: flex;
}

.bird {
  border-style: solid;
  border-color: var(--colour-bird-border);
  border-width: 0 2px 2px 0;
  border-top-style: none;
  border-top-color: currentColor;
  border-left-style: none;
  border-left-color: currentColor;
  border-color: var(--colour-bird-border);
  box-shadow: var(--colour-bird-border) 1px 2px 4px 0px;
  background-color: var(--colour-background2);
  border-radius: 15px;
  width: 100%;
  max-height: 0;
  margin-bottom: 1rem;
  padding: .5rem;
  transition: all .5s ease-out;
  display: flex;
  overflow: hidden;
}

@media (hover: hover) and (pointer: fine) {
  .bird {
    &:hover {
      box-shadow: var(--colour-highlight) 1px 2px 4px 0px;
    }
  }
}

.bird__recordings__container {
  font-family: var(--font-family-fun);
  cursor: pointer;
  flex-direction: column;
  grid-area: recording;
  justify-content: space-between;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  display: flex;

  & svg {
    width: 1.1rem;
    height: 1.1rem;
    margin: 0 .2rem;
  }
}

.scientific__name {
  font-style: italic;
}

.bird__recordings {
  flex-direction: row;
  align-items: center;
  padding: 1px;
  display: flex;
}

.audio__text {
  text-align: center;
}

.audio__image {
  width: 1.1rem;
  height: 1.1rem;
  margin: 0 .2rem;
}

.bird__image__container {
  cursor: pointer;
  border-radius: 15px;
  grid-area: image;
  justify-content: center;
  align-items: center;
  width: 37%;
  margin: 0;
  padding: 0;
  display: inline-flex;
  position: relative;
}

.bird__hidden {
  margin-bottom: none;
  border: none;
  height: 0;
  padding: 0;
}

.bird__image {
  object-fit: cover;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

.info__icon {
  width: 25px;
  height: 25px;
  padding-top: .2rem;
  position: absolute;
  top: 0;
  right: 0;
}

.info__icon__audio {
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding-top: .2rem;
  position: absolute;
  top: 6px;
  left: 8.5px;
}

.heart__icon {
  cursor: pointer;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 8px;
  right: 8.5px;
}

.spinner {
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 4rem;
  display: flex;

  & svg {
    margin: auto;
  }
}

.spinner__section, .spinner__overlay {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 17rem;
  display: flex;
}

.spinner_svg {
  max-width: 50px;
  max-height: 50px;
  animation: 2s linear infinite rotate;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bird__info__container {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  max-width: 63%;
  padding-left: .75rem;
  display: flex;
}

.bird__title_container {
  justify-content: space-between;
  display: flex;

  & svg {
    width: 3rem;
    height: 3rem;
    margin: 0 .2rem;
  }
}

.bird__info__title {
  grid-area: title;
  margin: 0;
}

.bird__details__container {
  padding-top: 2px;
}

.settings__container {
  width: 100%;
  margin-top: 1rem;
  transition: none;
}

.setting {
  justify-content: space-between;
  margin-right: 1rem;
  display: flex;
  position: relative;
}

.setting__section {
  margin: 1.5rem 1rem 1rem;
}

.setting__description {
  flex-direction: column;
  justify-content: space-between;
  padding: .5rem 0 .5rem 1rem;
  display: flex;
}

.season__colours__container {
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1rem;
  display: flex;
}

.season__colours__container button {
  background: var(--colour-background2);
  font-family: var(--font-family-fun);
  color: var(--font-color);
  border: 1px solid #d3d3d3;
  border-radius: .5rem;
  width: 60px;
  height: 60px;
  box-shadow: 0 1px 4px #63636333;

  &:hover {
    border-color: var(--colour-highlight);
    box-shadow: var(--colour-highlight) 0px 1px 4px 0px;
    cursor: pointer;
    color: var(--colour-highlight);
  }
}

.btn__season {
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    & svg {
      fill: var(--colour-highlight);
    }
  }
}

.svg__container__themes {
  fill: #63636333;
  width: 40px;
  height: 40px;
}

.theme__range {
  margin: 1rem 0;
}

.slider {
  -webkit-appearance: none;
  background: var(--colour-background2);
  border-radius: 15px;
  outline: none;
  width: 100%;
  height: 3px;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  background: var(--colour-bird);
  cursor: pointer;
  border-radius: 25px;
  width: 20px;
  height: 20px;
}

.bird__information__container {
  z-index: 5;
  background-color: var(--colour-background-modal);
  border-radius: 20px;
  max-width: 400px;
  margin: auto;
  position: fixed;
  top: 20px;
  left: 20px;
  right: 20px;
}

.bird__information {
  color: #000;
  flex-direction: column;
  margin: 10px;
  display: flex;
}

.bird__information__image__container {
  background-color: #e2e2e2;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 100%;
  max-height: 60vh;
  overflow: hidden;
}

.bird__information__image {
  border-radius: 20px;
  max-width: 100%;
}

.image__attribution {
  background-color: #e2e2e2;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.bird__information__attribution-owner {
  overflow-wrap: break-word;
  max-width: 70%;
}

.bird__information__audio__attribution {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.audio__embed {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.audio__embed p {
  padding-left: .6rem;
}

.close-info__btn {
  margin: none;
  background-color: #f5f5f5;
  border-radius: 25px;
  width: 31px;
  height: 31px;
  position: absolute;
  right: 0;
  transform: translate(12.5px, -12.5px);

  &:hover {
    transform: translate(12.5px, -12.5px);
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.overlay {
  z-index: 5;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay-blur {
  z-index: 5;
  opacity: .6;
  background-color: #000;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.border__none {
  border: none;
}

@keyframes quiet {
  25% {
    transform: scaleY(.6);
  }

  50% {
    transform: scaleY(.4);
  }

  75% {
    transform: scaleY(.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(.4);
  }

  75% {
    transform: scaleY(.6);
  }
}

@keyframes loud {
  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(.4);
  }

  75% {
    transform: scaleY(1.2);
  }
}

.audio__selector__soundwave {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.soundwave_container {
  --boxSize: 8px;
  --gutter: 4px;
  width: calc((var(--boxSize)  + var(--gutter)) * 5);
  justify-content: space-between;
  height: 36px;
  display: flex;
}

.wave {
  width: var(--boxSize);
  background: var(--colour-highlight);
  border-radius: 8px;
  height: 100%;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  transform: scaleY(.4);
}

.wave1 {
  animation-name: quiet;
}

.wave2 {
  animation-name: normal;
}

.wave3 {
  animation-name: quiet;
}

.wave4 {
  animation-name: loud;
}

.wave5 {
  animation-name: quiet;
}

.stop_animation {
  animation: none;
}

.loading__colour__list {
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading__colour__list li {
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  list-style: none;
  animation: 1.6s ease-in-out infinite grow;
}

@keyframes grow {
  0%, 40%, 100% {
    transform: scale(.2);
  }

  20% {
    transform: scale(1);
  }
}

.loading__colour__list li:first-child {
  background: #ff0;
  animation-delay: -1.4s;
  box-shadow: 0 0 50px #ff0;
}

.loading__colour__list li:nth-child(2) {
  background: #76ff03;
  animation-delay: -1.2s;
  box-shadow: 0 0 50px #76ff03;
}

.loading__colour__list li:nth-child(3) {
  background: #f06292;
  animation-delay: -1s;
  box-shadow: 0 0 50px #f06292;
}

.loading__colour__list li:nth-child(4) {
  background: #4fc3f7;
  animation-delay: -.8s;
  box-shadow: 0 0 50px #4fc3f7;
}

.loading__colour__list li:nth-child(5) {
  background: #ba68c8;
  animation-delay: -.6s;
  box-shadow: 0 0 50px #ba68c8;
}

.loading__colour__list li:nth-child(6) {
  background: #f57c00;
  animation-delay: -.4s;
  box-shadow: 0 0 50px #f57c00;
}

.loading__colour__list li:nth-child(7) {
  background: #673ab7;
  animation-delay: -.2s;
  box-shadow: 0 0 50px #673ab7;
}

.audio__track__details {
  white-space: nowrap;
  font-family: var(--font-family-sans);
  max-width: 100vw;
  font-size: 12px;
  font-style: italic;
  display: flex;
}

.audio__track__details div {
  overflow: hidden;
}

.audio__track__details__bird {
  white-space: nowrap;
}

.audio__track__details__scroller__text {
  z-index: 0;
  animation: 10s linear infinite trackScroller;
}

.track__details__highlight {
  color: var(--colour-highlight);
  font-weight: 800;
}

@keyframes trackScroller {
  0% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(var(--scroll-animation-translateX));
  }

  50% {
    transform: translateX(var(--scroll-animation-translateX));
  }

  90% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

@media (orientation: landscape) and (width >= 720px) {
  body {
    font-family: var(--font-family-sans);
    background: #131313;
  }

  .container {
    grid-template: "header header" 5rem
                   "nav content" calc(100vh - 6rem - 80px)
                   / .8fr 2fr;
    max-width: 800px;
    margin-top: 1rem;
    margin-bottom: 5rem;
  }

  .header {
    background: var(--colour-background);
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }

  .header__logo__container {
    max-width: 50%;
    height: 100%;
  }

  .logo {
    height: 100%;
  }

  .search__container {
    align-items: center;
    height: 100%;
    padding: 0;
  }

  .search {
    background-color: #fff;
    border-radius: 10rem;
    align-items: center;
    max-width: 85vw;
    height: 2rem;
    margin-right: 0;
    padding-left: 2rem;
    display: flex;
  }

  .filter__list {
    right: -1rem;
  }

  .nav {
    border-color: var(--colour-background2);
    margin-top: 0;
  }

  .birds__container {
    background: var(--colour-background);
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 6rem - 80px);
    display: flex;
    overflow: hidden scroll;
  }

  .audio__container {
    font-family: var(--font-family-fun);
    color: #fff;
    background-color: #1a1a1a;
    flex-direction: row;
    max-width: 800px;
    height: 80px;
    font-size: 1.1rem;
    display: flex;
  }

  .audio__container__overlay {
    height: 80px;
  }

  .audio__playback__container {
    height: 100%;
    margin: 0;
    padding-left: .8rem;
  }

  .audio__controls__container {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 800px;
    height: 100%;
    margin: 0;
    display: flex;
  }

  .btn__selector {
    cursor: pointer;
    background-color: #0000;
  }

  .btn__change__track {
    margin-bottom: 0;
  }

  .audio__selector__container {
    background-color: #191919;
    width: 400px;
    height: 75%;
    margin-left: 1rem;
    margin-right: 40px;
    padding: 0 .6rem;
  }

  .audio__selector__type {
    width: 33%;
  }

  .loading__colour__container {
    position: relative;
    left: 95%;
  }

  .bird__information__container {
    border-radius: 20px;
    max-width: 700px;
    max-height: 90vh;
    top: 50%;
    left: 20px;
    right: 20px;
    transform: translate(0, -50%);
  }

  .bird__information__image__container {
    max-height: 80vh;
  }

  .bird__information {
    flex-direction: row;
    display: flex;
  }

  .bird__information__image__attribution {
    font-size: .5rem;
  }

  .audio__embed {
    align-items: none;
    margin-top: 0;
  }

  .info__icon__audio {
    position: absolute;
    inset: auto 4.5px 10px auto;
  }

  .audio__track__details {
    background-color: #000000be;
    width: min(100vw, 800px);
    position: absolute;
    top: 0;
    transform: translateY(-1rem);
  }
}

.switch {
  width: 60px;
  height: 34px;
  margin-top: .5rem;
  margin-bottom: .5rem;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle_slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.toggle_slider:before {
  content: "";
  background-color: #fff;
  width: 26px;
  height: 26px;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

input:checked + .toggle_slider {
  background-color: var(--colour-bird);
}

input:focus + .toggle_slider {
  box-shadow: 0 0 1px var(--colour-bird);
}

input:checked + .toggle_slider:before {
  transform: translateX(26px);
}

.toggle_slider.round {
  border-radius: 34px;
}

.toggle_slider.round:before {
  border-radius: 50%;
}

.error__container {
  justify-content: center;
  display: flex;
}

.error__message {
  font-family: var(--font-family-fun);
  padding: .5rem;
  font-size: 1.5rem;
}
/*# sourceMappingURL=index.10eb85e3.css.map */
